import CreatePollModal from './CreatePollModal';
import DiscussionPoll from './DiscussionPoll';
import EditPollModal from './EditPollModal';
import ListVotersModal from './ListVotersModal';

export const components = {
  CreatePollModal,
  DiscussionPoll,
  EditPollModal,
  ListVotersModal,
};
